<template>
  <Main />
</template>

<script>
import Main from './Main.vue'
export default {
  components: {
    Main,
  },
  mounted() {
  },
}
</script>
